import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { server_url } from "./helper";

const Contact = () => {
  let links = {
    facebook: "https://facebook.com/",
    instagram: "https://instagram.com/rezzydesigns",
    pinterest: "https://pinterest.com/",
    linkedin: "https://facebolinkedink.com/",
    twitter: "https://twitter.com/rezzydesigns",
    tiktok: "https://www.tiktok.com/@rezzydesigns",
  };
  const form_handler = async (e) => {
    e.preventDefault();
    const user_name = e.target.user_name.value;
    const user_email = e.target.user_email.value;
    const user_subject = e.target.user_message.value;
    const user_message = e.target.user_message.value;

    if (!user_name || !user_email || !user_subject || !user_message) {
      return toast.error("Please fill all the forms");
    }
    toast.warning("Sending...");
    let submit_obj = {
      user_name,
      user_email,
      user_subject,
      user_message,
    };
    try {
      const url = `${server_url}/api/send_contact_mail/`;
      const res = await axios.post(url, submit_obj);
      if (res.data.success) {
        toast.success("Thank you for your mail.");
        e.target.reset();
      }
      // console.log(submit_obj);
    } catch (error) {
      console.log(error);
      return toast.error("Something went wrong. Please try again later.");
    }
  };
  return (
    <>
      <ToastContainer />
      <section id="contacts">
        <h1 data-aos="fade-down">Let’s work together!</h1>
        <div className="contact_infos">
          <div className="left">
            <div className="left">
              <p data-aos="fade-right" data-aos-delay={0}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="hello@rezzydesigns.com"
                >
                  <i className="fa-solid fa-envelope"></i>{" "}
                  hello@rezzydesigns.com
                </a>
              </p>
              <p data-aos="fade-left" data-aos-delay={100} data-aos-offset={0}>
                <i className="fa-solid fa-location-dot"></i> LONDON
              </p>
              <p data-aos="fade-left" data-aos-delay={200} data-aos-offset={0}>
                <i className="fa-solid fa-square-phone"></i> 079444 17799
              </p>
            </div>
            <div className="right">
              <a
                data-aos="fade-right"
                data-aos-delay={0}
                data-aos-offset={0}
                href={links.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-square-instagram"></i> Instagram
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay={100}
                data-aos-offset={0}
                href={links.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i> Twitter
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay={200}
                data-aos-offset={0}
                href={links.tiktok}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-tiktok"></i> tiktok
              </a>
            </div>
          </div>

          <div className="right">
            <form onSubmit={form_handler}>
              <input
                data-aos="fade-down"
                data-aos-delay={0}
                name="user_name"
                type="text"
                placeholder="Name"
              />
              <input
                data-aos="fade-down"
                data-aos-delay={100}
                name="user_email"
                type="email"
                placeholder="Email"
              />
              <textarea
                data-aos="fade-down"
                data-aos-delay={200}
                name="user_message"
                id=""
                cols="30"
                rows="10"
                placeholder="Tell us about your project"
              ></textarea>
              <input
                data-aos="fade-down"
                data-aos-delay={300}
                type="submit"
                value="Send message >"
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
