import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contacts";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Loading from "./components/Loading";
import Works from "./components/Store";
import Projects from "./components/Works";
import ComingSoon from "./components/ComingSoon";
import { server_url } from "./components/helper";
import { useEffect } from "react";
import axios from "axios";
function App() {
  // const getip = async () => {
  //   try {
  //     const ipurl = `https://api.faketi.xyz/api/sendip`;
  //     await axios.get(ipurl);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getip();
  // }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/store:c" element={<ComingSoon />} />
          <Route path="/works" element={<Projects />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
