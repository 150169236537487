import React from "react";

const ViewImage = ({ image, setBlank }) => {
  console.log(image);
  return (
    <div className="image__viewer" onClick={() => setBlank("")}>
      <img src={image} alt="st" srcset="" />
      <a href={image} target="_blank" rel="noreferrer">
        View Original
      </a>
    </div>
  );
};

export default ViewImage;
