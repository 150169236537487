import axios from "axios";
import { useEffect, useState } from "react";
import { server_url } from "./helper";
import ShowCategoryList from "./ShowCategoryList";
const HomeWorks = () => {
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`${server_url}/api/category`);
      setCategories([...data.category] || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <section id="home_works">
        {/* <div className="side_link">
          <Link to="/works=all">view our works</Link>
        </div> */}
        <div className="top">
          <h2 data-aos="fade-up">
            A CREATIVE DESIGNER THAT LIKES TO UNDERSTAND PEOPLE, THAT SEEKS
            SOLUTIONS TO PROBLEMS AND CREATES AN ENJOYABLE AND MEANINGFUL
            EXPERIENCE WITH MY ART.
          </h2>
          <div>
            <img src="./images/star.png" alt="star" className="star" />
          </div>
        </div>
        <ShowCategoryList categories={categories} />
        {/* <ProjectGif /> */}
        {/* <ContactComponent /> */}
      </section>
    </>
  );
};
export default HomeWorks;
