const Loading = () => {
  return (
    <>
      <div id="loader">
        <img className="loading_logo" src="./images/logo.png" alt="logo" />
        <h1>LOADING...</h1>
      </div>
    </>
  );
};
export default Loading;
