import { useEffect } from "react";
import Typewriter from "typewriter-effect/dist/core";
import Header from "./Header";

const Intro = () => {
  useEffect(() => {
    new Typewriter("#intro__text__loop", {
      strings: ["Visual Creative that<br />loves making art.", "Rezzy"],
      autoStart: true,
      loop: true,
      delay: 50,
      pauseFor: 1500,
      deleteSpeed: 0,
    });
  }, []);
  return (
    <>
      <section id="intro">
        <div className="contents">
          <Header />
          <div className="texts">
            <h1 id="intro__text__loop">
              Visual Creative that loves making art.
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};
export default Intro;
