import { useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [close, setClose] = useState(false);
  const header_hov = (e) => {
    e.target.classList.add("header_act");
  };
  const header_ov = (e) => {
    e.target.classList.remove("header_act");
  };

  const sm_head = () => {
    setClose(false);
    document.getElementById("__menus").classList.add("nav__menu");
  };
  const sm_headr = () => {
    setClose(true);
    document.getElementById("__menus").classList.remove("nav__menu");
  };
  return (
    <>
      <header>
        <div className="left" onClick={() => (window.location.href = "/")}>
          <img data-aos="fade-right" src="./images/logo.png" alt="logo" />
        </div>
        <div className="right">
          <i onClick={sm_head} id="header_on" className="fa-solid fa-bars"></i>
          <div id="__menus" className="menus">
            <div className="menu" data-aos="fade-down" data-aos-delay={0}>
              <i
                onClick={(e) => {
                  setClose(false);
                  sm_headr();
                }}
                id="close_menu"
                className="fa-solid fa-rectangle-xmark"
              ></i>
            </div>
            <div className="menu" data-aos="fade-down" data-aos-delay={100}>
              <NavLink
                onClick={(e) => setClose(true)}
                onMouseLeave={(e) => header_ov(e)}
                onMouseOver={(e) => header_hov(e)}
                end
                to="/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Home
              </NavLink>
            </div>
            <div className="menu" data-aos="fade-down" data-aos-delay={100}>
              <NavLink
                onClick={(e) => setClose(true)}
                onMouseLeave={(e) => header_ov(e)}
                onMouseOver={(e) => header_hov(e)}
                to="/about"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                About
              </NavLink>
            </div>

            <div className="menu" data-aos="fade-down" data-aos-delay={150}>
              <NavLink
                onClick={(e) => setClose(true)}
                onMouseLeave={(e) => header_ov(e)}
                onMouseOver={(e) => header_hov(e)}
                to="/works"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Works
              </NavLink>
            </div>
            <div className="menu" data-aos="fade-down" data-aos-delay={100}>
              <NavLink
                onClick={(e) => setClose(true)}
                onMouseLeave={(e) => header_ov(e)}
                onMouseOver={(e) => header_hov(e)}
                to="/store=all"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Store
              </NavLink>
            </div>
            <div className="menu" data-aos="fade-down" data-aos-delay={200}>
              <NavLink
                onClick={(e) => setClose(true)}
                onMouseLeave={(e) => header_ov(e)}
                onMouseOver={(e) => header_hov(e)}
                to="/contacts"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
