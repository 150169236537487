import ContactComponent from "./ContactComponent";
import Header from "./Header";
const Contact = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Header />
      <ContactComponent title="Let’s work together!" />
    </>
  );
};
export default Contact;
