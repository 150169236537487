import { Link } from "react-router-dom";

const Footer = ({ showTitle = true }) => {
  let links = {
    facebook: "https://facebook.com/",
    instagram: "https://instagram.com/rezzydesigns",
    pinterest: "https://pinterest.com/",
    linkedin: "https://facebolinkedink.com/",
    twitter: "https://twitter.com/rezzydesigns",
    tiktok: "https://www.tiktok.com/@rezzydesigns",
  };
  return (
    <>
      <footer>
        <div className="top">
          <div className="con_redirect" data-aos="fade-up">
            <Link to="/contacts">Start a Project</Link>
          </div>
          <div className="contacts">
            <div className="mails">
              <p data-aos="fade-left" data-aos-delay={0} data-aos-offset={0}>
                <a
                  href="mailto:hello@rezzydesigns.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-envelope"></i>{" "}
                  hello@rezzydesigns.com
                </a>
              </p>
              <p data-aos="fade-left" data-aos-delay={100} data-aos-offset={0}>
                <i className="fa-solid fa-location-dot"></i> LONDON
              </p>
              <p data-aos="fade-left" data-aos-delay={200} data-aos-offset={0}>
                <i className="fa-solid fa-square-phone"></i> 079444 17799
              </p>
            </div>
            <div className="socials">
              <a
                data-aos="fade-right"
                data-aos-delay={0}
                data-aos-offset={0}
                href={links.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-square-instagram"></i> Instagram
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay={100}
                data-aos-offset={0}
                href={links.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i> Twitter
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay={200}
                data-aos-offset={0}
                href={links.tiktok}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-tiktok"></i> tiktok
              </a>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div
            className="left"
            data-aos="fade-right"
            data-aos-delay={0}
            data-aos-offset={0}
          >
            <p>© 2023 RezzyDesigns. All rights reserved.</p>
          </div>
          <div className="right">
            <p data-aos="fade-left" data-aos-delay={0} data-aos-offset={0}>
              Developed by{" "}
              <a href="https://tarikul.dev/" target="_blank" rel="noreferrer">
                Snowden
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
