import { useEffect } from "react";
import { useState } from "react";
import HomeWorks from "./HomeWorks";
import Intro from "./Intro";
import Loading from "./Loading";
const Home = () => {
  const [loading, setLoading] = useState(true);

  // const loader = (e) => {
  //   setLoading(false);

  //   console.log("loaded");
  // };
  // window.onload = (e) => {
  //   console.log(e);
  // };
  setTimeout(() => {
    setLoading(false);
  }, 5000);
  useEffect(() => {}, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <Intro />
      <HomeWorks />
    </>
  );
};
export default Home;
