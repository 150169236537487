import React from "react";
import { Link } from "react-router-dom";
const ShowCategoryList = ({ categories }) => {
  return (
    <div className="category_box_container">
      {categories.map((c) => (
        <Link to={`/works#${c.name.replace(" ", "_")}`} className="category_box" key={c._id}>
          <h1 className="category_name">{c.name.toUpperCase()}</h1>
        </Link>
      ))}
    </div>
  );
};

export default ShowCategoryList;
