import React from "react";
import Header from "./Header";

const About = () => {
  return (
    <section id="about">
      <Header />
      <div className="about-box" data-aos="fade-left">
        <h1 className="about-title" data-aos="fade-bottom">
          About
        </h1>
        <p className="about-text" data-aos="fade-left" data-aos-delay="300">
          Hi there! <br /> My name is REZZY and I am 21 years old, originating
          from the beautiful city of Cardiff in Wales, UK. As I am coming from a
          diverse background, I am deeply committed to making a positive impact
          for my community and working towards creating positive change for the
          better. As a self-taught graphic designer, I have always had a passion
          for creating art and bringing my visions to life through my designs. I
          began learning about graphic design at the age of 13 and have been
          dedicated to continuously improving my skills and expanding my
          knowledge ever since.
        </p>
        <p className="about-text" data-aos="fade-left" data-aos-delay="300">
          I am currently studying Graphic Design Communication at a University
          in London. Making the move to London at 20 to study was a big step for
          me personally, as it meant leaving behind the familiarity of my
          hometown for the fast-paced city life. However, I believe that this
          move will greatly benefit my work as it allows me to be more creative
          and connect with other creators and resources in this city. Through my
          dedication to learning and improvement, I was able to start
          freelancing, which has allowed me to connect with people from all
          different backgrounds and cultures. This has not only helped me grow
          as a designer but has also enabled me to create my own unique language
          with my designs. In each project I take on, I make sure to do my best
          in creating a vision that both myself and the client are confident in
          and proud of. When I'm not busy designing for clients, I still enjoy
          designing in my free time and creating my own personal art projects.
          While these projects may not be to everyone's taste, I believe that
          they can still be appreciated for the unique perspective and
          creativity they bring. I am always looking for new opportunities to
          showcase my talent and bring my creative ideas to life, with the goal
          of making a difference through my work.
        </p>
        <p className="about-text" data-aos="fade-right" data-aos-delay="600">
          Thank You for getting to know me a bit more and I hope you can do the
          same when we get to work.
        </p>
        <div className="about-icon" data-aos="fade-top" data-aos-delay="900">
          <img src="./images/star.png" alt="star" />
        </div>
      </div>
    </section>
  );
};

export default About;
