import axios from "axios";
import { useEffect, useState } from "react";
import Header from "./Header";
import { server_url } from "./helper";
import ViewImage from "./ViewImage";

/*

[{
    PROJECT_CATEGORY_1: {foo: bar}
    PROJECT_CATEGORY_2: {}
    PROJECT_CATEGORY_3: {}
}]

*/
const mapCategoriesToObject = (categories) => {
  return categories.reduce((acc, curr) => {
    acc[curr.name] = [];
    return acc;
  }, {});
};

const Projects = () => {
  const [categories, setCategories] = useState([]);
  const [works, setWorks] = useState();
  const [loading, setLoading] = useState(true);
  const [allRendered, setAllRendered] = useState(false);
  const [viewImage, setViewImage] = useState("");

  const get_location = () => {
    if (window.location.hash) {
      // console.log(window.location.hash);

      const target = document.querySelector(window.location.hash);
      // console.log(target)
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
        const child_target = document.querySelector(
          `${window.location.hash}__child`
        );
        // console.log(child_target);
      } else {
        console.log("target not found");
      }
    }
  };
  useEffect(() => {
    get_location();
  }, [categories]);

  const get_categories = async () => {
    try {
      setLoading(true);
      const url = `${server_url}/api/get_categories`;
      const send_obj = {
        category_type: "work",
      };
      let res = await axios.post(url, send_obj);
      console.log(res);
      setLoading(false);

      if (res.data.success) {
        setCategories(res.data.data);
        res.data.data.map((cat) => {
          // console.log(cat);
          return get_works(cat.name);
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const work_render = (data, ind) => {
    let img_file_name = data.uploadedPath;
    let img_url = `${server_url}/${img_file_name}`;
    const img = img_url;
    // console.log(img_url);
    let fade_data = "";
    if ((ind + 1) % 2 === 0) {
      fade_data = "fade-right";
    } else {
      fade_data = "fade-left";
    }

    return (
      <>
        <div
          key={`_y${ind + 2}`}
          title="View in Full Size"
          onClick={() => setViewImage(img)}
          // data-aos={fade_data}
          className="work"
        >
          <div className="img">
            <img src={img} alt="work" />
          </div>
          <div className="work_texts_bottom">
            <p className="work_name">{data.fileName}</p>
            <p className="work_category">
              {data.description
                ? data.description.toUpperCase()
                : data.category.name.toUpperCase()}
            </p>
            {/* <p className="work_category work_time">
              {new Date(data.createdAt).toLocaleString()}
            </p> */}
          </div>
        </div>
      </>
    );
  };
  const get_works = async (cat) => {
    console.log(cat);
    try {
      setLoading(true);
      const url = `${server_url}/api/get_works`;

      const send_obj = {
        category_name: cat,
        category_type: "work",
      };
      let res = await axios.post(url, send_obj);
      // console.log(res.data);
      setLoading(false);
      if (res.data.success) {
        setWorks({ ...works, [cat]: res.data.data });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let state = mapCategoriesToObject(categories);
    setWorks(state);
  }, [categories]);

  useEffect(() => {
    get_categories();
  }, []);
  const class_handler = (c) => {
    const target = document.getElementById(c);
    if (target.classList.contains("project_dv_none")) {
      target.classList.remove("project_dv_none");
    } else {
      target.classList.add("project_dv_none");
    }
  };
  const class_handler2 = (c) => {
    const target = document.getElementById(c);
    if (target.classList.contains("p_rotate")) {
      target.classList.remove("p_rotate");
    } else {
      target.classList.add("p_rotate");
    }
  };

  const render_category = (data, i) => {
    if (i + 1 === categories.length) {
      get_location();
      // console.log("finished");
    }
    return (
      <>
        <div
          className="p_category"
          data-aos="fade-down"
          data-aos-delay={i * 100}
          data-aos-offset={0}
          id={data.name.replace(" ", "_")}
        >
          <div
            id={`${data.name.replace(" ", "_")}__child`}
            onClick={(e) => {
              class_handler2(`pro_i_${data.name}`);
              class_handler(`pro_gtr_${data.name}`);
              get_works(data.name);
            }}
            className="up"
          >
            <p>{data.name.toUpperCase()}</p>
            <i
              id={`pro_i_${data.name}`}
              className="fa-solid fa-caret-right"
            ></i>
          </div>
          <div
            id={`pro_gtr_${data.name}`}
            className="render_project_div project_dv_none"
          >
            {works && works[data.name]
              ? works[data.name].map(work_render)
              : null}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <section id="projects">
        <Header />
        {viewImage ? (
          <ViewImage image={viewImage} setBlank={setViewImage} />
        ) : null}
        <h1 data-aos="fade-down">
          {/* Do you love what you do enough to do it on your own time? */}
        </h1>

        <div className="project_names">
          {categories
            ? categories.map(render_category)
            : loading
            ? "Loading..."
            : "No data found!"}
        </div>
      </section>
    </>
  );
};
export default Projects;
