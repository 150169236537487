import React from "react";
import Header from "./Header";
const ComingSoon = () => {
  return (
    <>
      <Header />
      <section id="coming__soon">
        <div>Coming Soon...</div>
      </section>
    </>
  );
};

export default ComingSoon;
